@media (max-width: 450px) {
  .header {
    border-top: solid 4px var(--black);
    height: 80px;
  }

  .header h1 {
    font-size: 30px;
  }

  .home {
    flex-flow: column-reverse;

    overflow: hidden;

    height: auto;
  }

  .home .right-side {
    border: none;

    height: auto;
    width: 100%;

    padding: 30px 0px;
  }

  .home .right-side img {
    height: 250px;
  }

  .home .left-side {
    background: #f7cb45;

    height: auto;
    width: 100%;

    padding: 20px 20px 40px 20px;
  }

  .home .left-side .introduction h1 {
    font-size: 40px;
  }

  .left-side .introduction a:hover {
    color: var(--dark);
  }

  .quote h1 {
    font-size: 20px;

    margin: 0px 30px;

    text-align: center;
  }

  .projects-header h1 {
    font-size: 20px;

    margin: 0px 30px;
  }

  .projects-cards {
    width: 80%;
  }

  .project-card-item {
    width: 100%;

    height: auto;

    padding: 5px;

    font-size: 14px;

    margin-bottom: 10px;
  }

  .code-header h1 {
    font-size: 20px;
  }

  .code-snippets {
    width: 80%;
  }

  .code-items a {
    font-size: 18px;
  }

  .hire-me a {
    width: 80%;

    font-size: 15px;
  }

  .footer aside {
    padding: 20px;
  }

  .footer p {
    font-size: 15px;
  }

  .footer .right-side a {
    width: 40px;
    height: 40px;
  }
}
