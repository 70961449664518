@import url(https://fonts.googleapis.com/css?family=Poppins);
* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

body {
  font-family: "poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --yellow-light: #f7cb45;
  --purple-dark: #d8baff;

  --black: black;
  --white: white;
}

a:hover {
  color: #f7cb45;
  color: var(--yellow-light);
}

::selection {
  color: white;
  background-color: black;
}

.main-container {
  max-width: 1900px;

  margin: auto;
}

.header {
  height: 140px;

  display: grid;
  place-items: center;

  text-align: center;
}

.header h1 {
  font-size: 80px;
  font-weight: bold;
}

.home {
  border-top: solid 4px var(--black);

  height: calc(100vh - 140px);
  max-height: 1000px;

  display: flex;
}

.home aside {
  display: grid;
  place-items: center left;
}

.home .left-side {
  background: var(--purple-dark);

  height: 100%;
  width: 50%;

  padding: 0px 50px;
}

.home .right-side {
  border-left: solid 4px var(--black);

  background: var(--yellow-light);

  height: 100%;
  width: 50%;
}

.left-side .introduction h1 {
  font-size: 60px;

  padding-bottom: 20px;
}

.left-side .introduction p {
  font-size: 25px;

  padding-bottom: 40px;
}

.left-side .introduction p a {
  color: inherit;
}

.left-side .introduction a.contact-me-link {
  text-decoration: none;

  font-size: 20px;

  color: var(--white);
  background-color: var(--black);

  padding: 10px 20px;

  border-radius: 5px;
}

.left-side .introduction a:hover {
  color: var(--yellow-light);
}

.home .right-side {
  display: grid;
  place-items: center;
}

.home .right-side img {
  height: 400px;

  -webkit-user-select: none;

          user-select: none;
}

.quote {
  height: 140px;
  width: 100%;

  display: grid;
  place-items: center;

  background-color: var(--black);
}

.quote h1 {
  color: var(--white);

  font-size: 30px;
}

.hire-me,
.code-header,
.projects-header,
.feedback-header {
  height: 140px;

  text-align: center;

  display: grid;
  place-items: center;
}

.projects-cards {
  width: 70%;

  margin: auto;

  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;
}

.project-card-item {
  height: 300px;
  width: 48%;

  border: solid 4px var(--black);
  border-radius: 8px;

  margin-bottom: 40px;

  cursor: pointer;

  color: var(--black);

  display: grid;
  place-content: center;

  text-align: center;
}

.project-card-item .wrapper {
  height: 60px;
}

.project-card-item.color-1 {
  background: #ff9988;
}

.project-card-item.color-2 {
  background: #ffd2f9;
}
.project-card-item.color-3 {
  background: var(--purple-dark);
}
.project-card-item.color-4 {
  background: #bad6ff;
}
.project-card-item.color-5 {
  background: #ff9e68;
}

.code-card-item {
  border: solid 4px var(--black);
  border-radius: 8px;
}

.code-header h1 {
  font-size: 30px;
}

.code-snippets {
  width: 70%;

  margin: auto;
}

.code-items {
  list-style: none;
}

.code-items li {
  border-bottom: solid 2px var(--black);

  margin-bottom: 5px;
}

.code-items li:hover {
  color: var(--yellow-light);
  border-color: var(--yellow-light);
}

.code-items a {
  text-decoration: none;

  color: var(--black);

  font-size: 25px;

  padding: 10px 0px;

  color: inherit;
}

.hire-me a {
  font-size: 20px;

  color: var(--white);
  background: #000;

  padding: 10px 20px;

  width: 50%;

  text-decoration: none;

  border-radius: 5px;
}

.hire-me a:hover {
  color: var(--yellow-light);
}

.footer {
  width: 100%;
  height: 140px;

  display: flex;

  background: #000;
}

.footer aside {
  display: grid;

  width: 100%;

  color: var(--white);

  padding: 0px 30px;
}

.footer .left-side {
  place-items: center left;
}

.footer .right-side {
  display: flex;

  flex-direction: row-reverse;
  align-items: center;
}

.footer .right-side a {
  width: 50px;
  height: 50px;

  display: grid;
  place-items: center;
}

.footer .right-side a svg {
  height: 27px;
  width: 27px;

  stroke: var(--white);
}

.footer .right-side a:hover svg {
  stroke: var(--yellow-light);
}

@media (max-width: 450px) {
  .header {
    border-top: solid 4px var(--black);
    height: 80px;
  }

  .header h1 {
    font-size: 30px;
  }

  .home {
    flex-flow: column-reverse;

    overflow: hidden;

    height: auto;
  }

  .home .right-side {
    border: none;

    height: auto;
    width: 100%;

    padding: 30px 0px;
  }

  .home .right-side img {
    height: 250px;
  }

  .home .left-side {
    background: #f7cb45;

    height: auto;
    width: 100%;

    padding: 20px 20px 40px 20px;
  }

  .home .left-side .introduction h1 {
    font-size: 40px;
  }

  .left-side .introduction a:hover {
    color: var(--dark);
  }

  .quote h1 {
    font-size: 20px;

    margin: 0px 30px;

    text-align: center;
  }

  .projects-header h1 {
    font-size: 20px;

    margin: 0px 30px;
  }

  .projects-cards {
    width: 80%;
  }

  .project-card-item {
    width: 100%;

    height: auto;

    padding: 5px;

    font-size: 14px;

    margin-bottom: 10px;
  }

  .code-header h1 {
    font-size: 20px;
  }

  .code-snippets {
    width: 80%;
  }

  .code-items a {
    font-size: 18px;
  }

  .hire-me a {
    width: 80%;

    font-size: 15px;
  }

  .footer aside {
    padding: 20px;
  }

  .footer p {
    font-size: 15px;
  }

  .footer .right-side a {
    width: 40px;
    height: 40px;
  }
}

