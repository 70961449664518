@import url("https://fonts.googleapis.com/css?family=Poppins");

* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

body {
  font-family: "poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --yellow-light: #f7cb45;
  --purple-dark: #d8baff;

  --black: black;
  --white: white;
}

a:hover {
  color: var(--yellow-light);
}

::selection {
  color: white;
  background-color: black;
}
